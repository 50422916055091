<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Información recibida"></header-title>
      <v-row class="mb-5">
        <v-col cols="12" md="3">
          <v-select
            label="Gestión"
            :items="years"
            v-model="options.year_id"
            item-text="gestion"
            item-value="id"
            @change="getActivities"
            hide-details
            clearable
          ></v-select>
        </v-col>
        <v-col cols="12" md="4"
          ><v-text-field
            class="mr-1"
            label="Buscar"
            v-model="options.search"
            append-icon="mdi-magnify"
            @input="changeSearch"
            hide-details
            clearable
          ></v-text-field
        ></v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="activities"
        :loading="loading"
        :page.sync="options.page"
        :items-per-page="options.itemsPerPage"
        hide-default-footer
        calculate-widths
        item-key="name"
        class="elevation-1"
        v-cloak
      >
        <template v-slot:[`item.numeration`]="{ item }">
          <td>
            {{
              (options.page - 1) * options.itemsPerPage +
              (activities.indexOf(item) + 1)
            }}
          </td>
        </template>
        <template v-slot:[`item.date_limit`]="{ item }">
          <td>
            {{ item.fecha_limite | formatdate }}
          </td>
        </template>
        <template v-slot:[`item.files`]="{ item }">
          <td>
            <span v-if="item.plantilla">
              <a
                :href="`${serve}gerencia/plantilla/${item.plantilla}`"
                target="_blank"
                >ver o descargar</a
              >
            </span>
            <span class="grey--text" v-else>sin archivo</span>
          </td>
        </template>
        <template v-slot:[`item.status`]="{ item }">
          <td>
            <span
              v-if="
                item.estado == 1 || item.estado == true || item.estado == 'true'
              "
            >
              <v-chip small color="green lighten-4">activo</v-chip>
            </span>
            <span v-else>
              <v-chip small color="red lighten-4">inactivo</v-chip>
            </span>
          </td>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <td>
            <v-btn
              small
              color="success"
              :loading="btn_loadinf"
              @click="showDialog(item)"
            >
              Revisar
            </v-btn>
          </td>
        </template>
      </v-data-table>
      <div class="v-data-footer mt-2">
        <v-row>
          <v-col cols="12" sm="4">
            <div class="v-data-footer__select">
              Filas por página:
              <v-select
                :items="[10, 20, 30, 50, 100]"
                v-model="options.itemsPerPage"
                @change="changePerPage()"
                hide-details
                class="my-0"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="12" sm="8" class="text-center">
            <v-pagination
              v-model="options.page"
              :length="options.pageCount"
              :total-visible="5"
              @input="changePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-container>
    <v-dialog
      v-if="idialog"
      v-model="idialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Información</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn color="red" dark @click="idialog = false"> Cerrar </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-row class="ma-1">
          <v-col cols="12" sm="8">
            <h4 class="teal--text text--darken-2">Datos de la actividad</h4>
            <v-row class="mt-2">
              <v-col class="py-1" cols="12" sm="12"
                >Tipo de información:
                <span class="grey--text text--darken-2">{{
                  activity.tipo_informacion.descripcion
                }}</span></v-col
              >
              <v-col class="py-1" cols="12" sm="12"
                >Descripción:
                <span class="grey--text text--darken-2">{{
                  activity.descripcion
                }}</span></v-col
              >
              <v-col class="py-1" cols="12" sm="12"
                >Fecha límite:
                <span class="grey--text text--darken-2">{{
                  activity.fecha_limite | formatdate
                }}</span></v-col
              >
            </v-row>
          </v-col>
          <v-col cols="12" sm="4">
            <span
              :class="`text-subtitle-2 ${activity.estado ? '' : 'red--text'}`"
            >
              Actividad
              {{ activity.estado ? "vigente" : "no vigente" }}
            </span>
            <p class="grey--text text--darken-2">
              Revisar los documentos enviados. Para ello puede filtrar por
              ESFM/UA.
            </p>
          </v-col>
        </v-row>
        <v-divider class="mt-5"></v-divider>
        <div class="mx-4">
          <h4 class="mt-2 mb-1 pb-1 teal--text text--darken-2">
            Información recibida
          </h4>
          <v-row>
            <v-col cols="12" sm="6">
              <v-autocomplete
                dense
                outlined
                label="Institución"
                :items="institutions"
                v-model="institution_id"
                item-text="nombre"
                item-value="id"
                @change="getInformation"
                hide-details
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    type="button"
                    color="default"
                    @click="clearFilter"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                    Quitar
                  </v-btn>
                </template>
                <span>Quitar filtro</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
        <v-row class="ma-1">
          <v-col cols="12">
            <div class="v-data-table theme--light">
              <div class="v-data-table__wrapper">
                <table>
                  <thead>
                    <tr>
                      <th class="text-left">#</th>
                      <th class="text-left">Institución</th>
                      <th class="text-left">Descripción</th>
                      <th class="text-left">Documento</th>
                      <th class="text-left">Fecha de envio</th>
                      <th class="text-left">Remitente</th>
                      <th class="text-left">Estado</th>
                      <th class="text-left">Revisor</th>
                      <th class="text-left">Observación</th>
                      <th class="text-left">Acciones</th>
                    </tr>
                  </thead>
                  <tbody v-if="informations.length > 0">
                    <tr v-for="(item, index) in informations" :key="index">
                      <td>{{ index + 1 }}</td>
                      <td>
                        {{ item.institucion ? item.institucion.nombre : "" }}
                      </td>
                      <td>{{ item.descripcion }}</td>
                      <td>
                        <a
                          :href="`${serve}gerencia/informacion/${item.documento}`"
                          target="_blank"
                          >ver o descargar</a
                        >
                      </td>
                      <td>{{ item.fecha_envio | formatfulldate }}</td>
                      <td>{{ item.remitente.nombre_completo }}</td>
                      <td>
                        <span
                          v-if="
                            item.estado_informacion.id == 1 ||
                            item.estado_informacion.id == 2
                          "
                        >
                          <v-chip small color="blue lighten-4">
                            {{ item.estado_informacion.descripcion }}
                          </v-chip>
                        </span>
                        <span v-else-if="item.estado_informacion.id == 4">
                          <v-chip small color="green lighten-4">
                            {{ item.estado_informacion.descripcion }}
                          </v-chip>
                        </span>
                        <span v-else>
                          <v-chip small color="red lighten-4">
                            {{ item.estado_informacion.descripcion }}
                          </v-chip>
                        </span>
                      </td>
                      <td>
                        {{
                          item.revisor ? item.revisor.nombre_completo : "---"
                        }}
                      </td>
                      <td>{{ item.observacion }}</td>
                      <td>
                        <v-btn
                          v-if="[1, 2, 4].includes(item.estado_informacion.id)"
                          small
                          color="success"
                          @click="attendSent(item)"
                        >
                          Atender
                        </v-btn>
                        <v-btn v-else small disabled> Atender </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="text-center grey--text" colspan="9">
                        No hay datos disponibles
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <v-dialog v-model="rdialog" scrollable max-width="500px">
      <v-form ref="form">
        <v-card>
          <v-card-title class="headline grey lighten-3" primary-title>
            <span class="headline">Atender</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <span class="text--darken-8 grey--text">
              Los campos con
              <b class="red--text">*</b> son requeridos.
            </span>
            <v-container grid-list-md>
              <v-row>
                <v-col cols="12">
                  <v-select
                    label="Estado *"
                    :items="estado_informacion"
                    v-model="information.estado_id"
                    item-text="descripcion"
                    item-value="id"
                    :rules="[(v) => !!v || 'Seleccione una opción']"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    type="text"
                    label="Observación"
                    v-model.trim="information.observacion"
                    autocomplete="off"
                    counter="100"
                    rows="2"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :loading="btn_loading"
              text
              @click.native="updateInformation()"
              >Enviar</v-btn
            >
            <v-btn color="red" text @click.native="rdialog = false"
              >Cerrar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-console */
import HeaderTitle from "@/components/HeaderTitle";
import Storage from "../../utils/storage";
import Endpoint from "../../utils/environment";
import Service from "../../services/request";
import { mapActions } from "vuex";
export default {
  name: "information-received",
  components: {
    HeaderTitle,
  },
  data() {
    return {
      loading: false,
      btn_loading: false,
      btn_loadinf: false,
      headers: [
        { text: "#", value: "numeration", align: "left", sortable: false },
        { text: "Tipo información ", value: "tipo_informacion.descripcion" },
        { text: "Actividad ", value: "descripcion" },
        { text: "Fecha límite ", value: "date_limit" },
        { text: "Plantilla ", value: "files", sortable: false },
        { text: "Estado ", value: "status" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      years: [],
      activities: [],
      informations: [],
      institutions: [],
      estado_informacion: [],
      activity: {
        id: "",
        tipo_informacion: {},
        descripcion: "",
        fecha_limite: "",
      },
      information: {
        id: "",
        estado_id: null,
        observacion: null,
      },
      options: {
        year_id: null,
        search: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
      },
      institution_id: "",
      idialog: false,
      rdialog: false,
      user: null,
      serve: "",
    };
  },
  computed: {
    blockPermission() {
      return this.informations.reduce(
        (acc, item) => (acc = acc > item.id ? acc : item.id),
        0
      );
    },
  },
  created() {
    this.serve = Endpoint.getServe();
  },
  mounted() {
    this.user = Storage.getUser();
    if (this.user && this.user.rid == 1) {
      this.getParams();
    } else {
      this.$router.replace({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["toast"]),
    getParams() {
      Service.svcInformationParams("get")
        .then((response) => {
          this.years = response.data[0];
          this.institutions = response.data[1];
          this.estado_informacion = response.data[2];

          const currentYear = new Date().getFullYear();
          const year = this.years.find((item) => item.gestion == currentYear);
          if (year) {
            this.options.year_id = year.id;
          }
          this.getActivities();
        })
        .catch((error) => {
          console.error("No cargo los registros", error);
        });
    },
    getActivities() {
      this.loading = true;
      Service.svcInformationPaginate("post", this.options)
        .then((response) => {
          this.loading = false;
          this.activities = response.data.rows;
          if (response.data.count > 0) {
            this.options.pageCount = Math.ceil(
              response.data.count / this.options.itemsPerPage
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error("Error al cargar registros", error);
        });
    },
    showDialog(data) {
      this.activity = data;
      this.institution_id = "";
      this.getInformation();
    },
    getInformation() {
      this.btn_loadinf = true;
      this.informations = [];
      Service.svcInformationByActivity("post", {
        actividad_id: this.activity.id,
        institucion_id: this.institution_id,
      })
        .then((response) => {
          this.btn_loadinf = false;
          this.informations = response.data;
          this.idialog = true;
        })
        .catch(() => {
          this.btn_loadinf = false;
        });
    },
    clearFilter() {
      this.institution_id = "";
      this.getInformation();
    },
    attendSent(data) {
      this.information.id = data.id;
      this.information.estado_id = data.estado_id;
      this.information.observacion = data.observacion;
      this.rdialog = true;
    },
    updateInformation() {
      if (this.$refs.form.validate()) {
        this.btn_loading = true;
        Service.svcInformation("put", this.information)
          .then((response) => {
            this.btn_loading = false;
            this.toast({ color: "success", text: response.data });
            this.rdialog = false;
            this.getInformation();
          })
          .catch(() => {
            this.btn_loading = false;
            console.error("Error al guardar");
          });
      }
    },

    changeSearch() {
      this.getActivities();
    },

    changePerPage() {
      this.options.page = 1;
      this.getActivities();
    },

    changePage() {
      this.getActivities();
    },
  },
};
</script>
